import React from 'react'
import InputMask from './InputMask.js'

function getSelection (el) {
  let start, end
  if (el.selectionStart !== undefined) {
    start = el.selectionStart
    end = el.selectionEnd
  } else {
    try {
      el.focus()
      let rangeEl = el.createTextRange()
      let clone = rangeEl.duplicate()
      rangeEl.moveToBookmark(document.selection.createRange().getBookmark())
      clone.setEndPoint('EndToStart', rangeEl)
      start = clone.text.length
      end = start + rangeEl.text.length
    }
    catch (e) { /* not focused or not visible */ }
  }
  return { start, end }
}

function setSelection(el, selection) {
  try {
    if (el.selectionStart !== undefined) {
      el.focus()
      el.setSelectionRange(selection.start, selection.end)
    } else {
      el.focus()
      let rangeEl = el.createTextRange()
      rangeEl.collapse(true)
      rangeEl.moveStart('character', selection.start)
      rangeEl.moveEnd('character', selection.end - selection.start)
      rangeEl.select()
    }
  }
  catch (e) { /* not focused or not visible */ }
}

class MaskedInput extends React.Component {

  static defaultProps = {value: ''}

  constructor(props) {
    super(props)
    let options = {pattern: this.props.mask, value: this.props.value, formatCharacters: this.props.formatCharacters}
    if (this.props.placeholderChar) {
      options.placeholderChar = this.props.placeholderChar
    }
    this.mask = new InputMask(options)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mask !== this.props.mask) {
      this.mask.setPattern(this.props.mask, {value: this.props.value, selection: getSelection(this.props.inputRef.current)})
      if (this.mask.selection.start) {
        this._updateInputSelection()
      }
    } else if (prevProps.value !== this.props.value && prevProps.value !== this.props.mask) {
      this.mask.setValue(this.props.value)
      this.forceUpdate()
    }
  }

  _updateMaskSelection() {
    this.mask.selection = getSelection(this.props.inputRef.current)
  }

  _updateInputSelection() {
    setSelection(this.props.inputRef.current, this.mask.selection)
  }

  _onChange = (e) => {
    let maskValue = this.mask.getValue()
    let incomingValue = e.target.value
    if (incomingValue !== maskValue) { // only modify mask if form contents actually changed
      this._updateMaskSelection()
      this.mask.setValue(incomingValue) // write the whole updated value into the mask
      e.target.value = this._getDisplayValue() // update the form with pattern applied to the value
      this._updateInputSelection()
    }
    if (this.props.onChange) {
      this.props.onChange(e)
    }
  }

  _onKeyDown = (e) => {
    if (e.key === 'Backspace') {
      e.preventDefault()
      this._updateMaskSelection()
      if (this.mask.backspace()) {
        let value = this._getDisplayValue()
        e.target.value = value
        if (value) {
          this._updateInputSelection()
        }
        if (this.props.onChange) {
          this.props.onChange(e)
        }
      }
    }
  }

  onBeforeInput = (e) => {
    // Ignore modified key presses
    // Ignore enter key to allow form submission
    if (e.metaKey || e.altKey || e.ctrlKey || e.key === 'Enter') { return }
    e.preventDefault()
    this._updateMaskSelection()
    if (this.mask.input((e.key || e.data))) {
      e.target.value = this.mask.getValue()
      this._updateInputSelection()
      if (this.props.onChange) {
        this.props.onChange(e)
      }
    }
  }

  _onPaste = (e) => {
    e.preventDefault()
    this._updateMaskSelection()
    // getData value needed for IE also works in FF & Chrome
    if (this.mask.paste(e.clipboardData.getData('Text'))) {
      e.target.value = this.mask.getValue()
      // Timeout needed for IE
      setTimeout(() => this._updateInputSelection(), 0)
      if (this.props.onChange) {
        this.props.onChange(e)
      }
    }
  }

  _getDisplayValue() {
    let value = this.mask.getValue()
    return value === this.mask.emptyValue ? '' : value
  }

  focus() {
    this.props.inputRef.current.focus()
  }

  blur() {
    this.props.inputRef.current.blur()
  }

  render() {
    let maxLength = this.mask.pattern.length
    let value = this._getDisplayValue()
    let eventHandlers = {onChange: this._onChange, onKeyDown: this._onKeyDown, onPaste: this._onPaste, onBeforeInput: this.onBeforeInput}
    let { size = maxLength, placeholder = this.mask.emptyValue } = this.props
    let { placeholderChar, formatCharacters, inputRef, ...cleanedProps } = this.props // eslint-disable-line no-unused-vars
    let ref = inputRef
    let inputProps = { ...cleanedProps, ...eventHandlers, ref, maxLength, value, size, placeholder }
    return <input {...inputProps} />
  }
}

export default MaskedInput