import Decimal from "decimal.js"
import {Duration} from "./Duration.js"
import {Expression} from "./Expression.js"

import {MC} from './MC.js'

const Value = {

  v: function(value, basicType = 'string', empty = false) {
    if (empty) {
      return {type: basicType, empty: true, value: ''}
    }
    if (MC.isPureNull(value)) {
      return {type: basicType, null: true, value: null}
    }
    if (basicType === 'collection') {
      return {type: basicType, value: MC.asArray(value)} 
    }
    if (Array.isArray(value)) {
      if (value.length > 0) {
        value = value[0]
      }
    }
    if (MC.isPlainObject(value)) {
      if ('anyType' === basicType || 'dataNode' === basicType) {
        return Value.fromJson(value)
      } else {
        return {type: basicType, value: Value.castToScalar(Value.v(value, 'anyType'), basicType).value}
      }
    }
    if ('dataNode' === basicType) {
      throw new Error('Can not cast "' + value + '" to data node value!')
    }
    if ('boolean' === basicType) {
      if (typeof value === 'string') {
        value = value.trim().toLowerCase()
        if (value === 'true') {
          return {type: basicType, value: 'true'}
        } else if (value === 'false') {
          return {type: basicType, value: 'false'}
        } else {
          throw new Error('Value "' + value + '" is not castable to boolean!');
        }
      } else {
        if (value === true) {
          return {type: basicType, value: 'true'}
        } else if (value === false) {
          return {type: basicType, value: 'false'}
        } else {
          throw new Error('Value "' + value + '" is not castable to boolean!');
        }
      }
    } else if (['integer', 'int', 'long', 'short', 'byte', 'decimal', 'double', 'float'].indexOf(basicType) > -1) {
      if (typeof value === 'string') {
        value = value.trim()
        if (value.startsWith('+')) {
          value = value.substring(1)
        }
      }
      if (['integer', 'long', 'int', 'short', 'byte'].indexOf(basicType) > -1) {
        let number = new Decimal(value)
        if (number.isInt()) {
          return {type: basicType, value: number.toFixed(0)}
        } else {
          throw new Error('Value "' + value + '" is not castable to integer!')
        }
      } else if (['float', 'double', 'decimal'].indexOf(basicType) > -1) {
        return {type: basicType, value: (new Decimal(value)).toFixed()}
      }
    } else if ('duration' === basicType) {
      var dur = new Duration()
      dur.parseIsoString(value)
      if (!dur.isValidDuration()) {
        throw new Error('Value "' + value + '" is not castable to duration!')
      }
      return {type: basicType, value: dur.toIsoString()}
    } else if (['date', 'time', 'dateTime'].indexOf(basicType) >= 0) {
      if (typeof value === 'string') {
        value = value.trim()
      }
      let lux = MC.dateTimeStringToLuxon(value)
      if (!lux.v.isValid) {
        throw new Error('Value "' + value + '" is not castable to ' + basicType + '!')
      }
      return {type: basicType, value: MC.luxonToDateTimeString(lux, basicType)}
    } else if ('hexBinary' == basicType) {
      value = ('' + value).trim()
      if (value.match(/^[0-9a-fA-F]+$/)) {
        return {type: basicType, value: value.toUpperCase()}
      } else if (value.match(/^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/)) {
        let expression = new Expression()
        expression.init(null, {}, null)
        value = expression.operatorDecodeBase64([Value.v(value, 'string')])
        value = expression.operatorEncodeHex([value])
        return {type: basicType, value: value.value}
      } else {
        return {type: basicType, value: '' + value}
      }
    } else if ('base64Binary' == basicType) {
      if (value.match(/^[0-9a-fA-F]+$/)) { //is hex
        value = ('' + value).trim().toUpperCase()
        let expression = new Expression()
        expression.init(null, {}, null)
        value = expression.operatorDecodeHex([Value.v(value, 'string')])
        value = expression.operatorEncodeBase64([value])
        return {type: basicType, value: value.value}
      } else {
        return {type: basicType, value: ('' + value).trim()}
      }
    } else {
      return {type: basicType, value: '' + value}
    }
  },
  dataNode: function(object) {
    if (MC.isPlainObject(object)) {
      return {type: 'anyType', value: object}
    } else {
      return Value.v(null)
    }
  },
  error: function(mess, path) {
    let val = {type: 'error', mess: mess}
    if (path) {
      val.path = path
    }
    return val
  },
  errorPathSet: function(value, path) {
    if (path && !value.path) {
      value.path = path
    }
  },
  errorAdjustMessage: function(val, mess) {
    val.mess = mess + " \n" + val.mess
  },
  getErrorMessage: function(value) {
    return (value.path ? ("Target path '" + value.path + "' \n") : "") + value.mess
  },
  isError: function(value) {
    return value.type == 'error' || value.isErrorInside
  },
  isErrorInside: function(value) {
    return value.isErrorInside
  },
  errorInside: function(value, mess) {
    if (!Value.isError(value)) {
      value.mess = mess
      value.isErrorInside = true
    }
  },
  fromJson: function(value, fromLiteral = false) {
    let res = {}
    if (Array.isArray(value)) {
      res.type = 'collection'
      res.value = []
      for (let it of value) {
        res.value.push(Value.fromJson(it, fromLiteral))
      }
    } else if (MC.isPlainObject(value)) {
      res.type = 'anyType'
      res.value = {}
      for (let key in value) {
        res.value[key] = Value.fromJson(value[key], fromLiteral)
      }
    } else {
      if (typeof value == "number") {
        res.type = 'decimal'
        res.value = Value.v(value, 'decimal').value
      } else if (typeof value == "boolean") {
        res.type = 'boolean'
        res.value = Value.v(value, 'boolean').value
      } else if (MC.isNull(value)) {
        res.type = 'string'
        res.value = null
        res.null = true
      } else if (fromLiteral) {
        res = Value.parseLiteral(value)
      } else {
        res.type = 'string'
        res.value = value
      }
    }
    return res
  },
  toJson: function(value, forceNumbers = false, asLiteral = false, emptyAsNull = false) {
    let res = null
    if (!value) {
      return res
    }
    if (value.null) {
      return null
    } else if (value.empty) {
      return emptyAsNull ? null : ""
    } else if (value.type == 'collection') {
      res = []
      for (let it of value.value) {
        res.push(asLiteral ? Value.toLiteral(it, undefined, true) : Value.toJson(it, forceNumbers, asLiteral, emptyAsNull))
      }
    } else if (value.type == 'anyType' && MC.isPlainObject(value.value)) {
      res = {}
      for (let key in value.value) {
        res[key] = asLiteral ? Value.toLiteral(value.value[key], undefined, true) : Value.toJson(value.value[key], forceNumbers, asLiteral, emptyAsNull)
      }
    } else {
      if (value.type == 'boolean') {
        res = (value.value === "true")
      } else if (Value.isNumber(value) && forceNumbers) {
        res = parseFloat(value.value)
      } else {
        res = value.value
      }
    }
    return res
  },
  isNumber: function(value) {
    return ['integer', 'int', 'long', 'short', 'byte', 'decimal', 'double', 'float'].indexOf(value.type) > -1
  },
  isDateOrTime: function(value) {
    return ['date', 'time', 'dateTime'].indexOf(value.type) >= 0
  },
  isDuration: function(value) {
    return value.type == 'duration'
  },
  isInt: function(value) {
    return ['integer', 'int', 'long', 'short', 'byte'].indexOf(value.type) > -1
  },
  isCollection: function(value) {
    return value.type == 'collection'
  },
  isCollectionNotEmpty: function(value) {
    return value.type == 'collection' && Array.isArray(value.value) && value.value.length > 0
  },
  isDataNode: function(value) {
    return value.type == 'anyType' || value.type == 'dataNode'
  },
  hasProperty: function(value, prop) {
    return (value.type == 'anyType' || value.type == 'dataNode') && !MC.isNull(value.value[prop])
  },
  getProperty: function(value, prop) {
    if (Value.hasProperty(value, prop)) {
      return value.value[prop]
    } else {
      return Value.v(null)
    }
  },
  collectionValue: function(value, i) {
    if (Value.isCollectionNotEmpty(value)) {
      return value.value
    } else {
      return []
    }
  },
  collectionItem: function(value, i) {
    if (Value.isCollectionNotEmpty(value) && value.value[i]) {
      return value.value[i]
    } else {
      return Value.v(null)
    }
  },
  collectionSize: function(value, i) {
    if (Value.isCollectionNotEmpty(value)) {
      return value.value.length
    } else {
      return 0
    }
  },
  isEmpty: function(value) {
    return value.empty === true
  },
  isEmptyString: function(value) {
    return value.type === 'string' && (value.empty || value.value === '')
  },
  isNull: function(value) {
    if (value.null === true) {
      return true
    }
    if (Value.isCollection(value)) {
      if (Value.collectionSize(value) == 0) {
        return true
      }
      for (let item of Value.collectionValue(value)) {
        if (item && !Value.isNull(item)) {
          return false
        }
      }
      return true
    }
    if (Value.isDataNode(value)) {
      for (let k in value.value) {
        if (k && !Value.isNull(value.value[k])) {
          return false
        }
      }
      return true
    }
    return false
  },
  isPureNull: function(value) {
    return value.null === true
  },
  isNullOrEmpty: function(value) {
    return Value.isEmpty(value) || Value.isNull(value)
  },
  castToScalar: function(value, type) {
    if (Value.isError(value)) {
      return value
    }
    if (Value.isCollection(value)) {
      if (value.value.length > 0) {
        value = value.value[0]
      } else {
        value = Value.v(null, type || 'string')
      }
    } else if (Value.isDataNode(value)) {
      for (let key in value.value) {
        value = value.value[key]
        break
      }
    }
    if (Value.isCollection(value) || Value.isDataNode(value)) {
      return Value.castToScalar(value, type)
    } else {
      if (value.type == 'string' && value.value === '') {
        return Value.v('', 'string', true)
      }
      return Value.v(value.value, type || value.type, Value.isEmpty(value))
    }
  },
  castToCollection: function(value) {
    if (!Value.isCollection(value)) {
      value = {type: 'collection',  value: Value.isPureNull(value) ? [] : [value], null: Value.isPureNull(value)}
    }
    return value
  },
  castToDataNode: function(value) {
    if (Value.isCollection(value)) {
      value = Value.castToDataNode(Value.collectionItem(value, 0))
    }
    if (Value.isNullOrEmpty(value) || Value.isDataNode(value)) {
      return value
    }
    throw new Error('Cannot cast "' + value.type + '" (' + JSON.stringify(Value.toLiteral(value)) + ') to data node value!')
  },
  isTrue: function(value) {
    if (Value.isNullOrEmpty(value)) {
      return false
    } else {
      return value.value == 'true'
    }
  },
  isFalse: function(value) {
    if (Value.isNullOrEmpty(value)) {
      return false
    } else {
      return value.value == 'false'
    }
  },
  getFirstNotNull: function(value) {
    if (Value.isNull(value)) {
      return Value.v(null)
    } 
    if (Value.isCollection(value)) {
      for (let v of value.value) {
        if (!Value.isNull(v)) {
          return v
        }
      }
      return {type: value.type, null: true, value: null}
    } else {
      return value
    }
  },
  collectionDepth: function(coll) {
    if (!Value.isCollection(coll)) {
      return 0
    }
    let depth = 1
    for (let item of this.collectionValue(coll)) {
      let itemDepth = Value.collectionDepth(item) + 1
      if (itemDepth > depth) {
        depth = itemDepth
      }
    }
    return depth
  },
  extend: function(target, source, respectNull = false) {
    let name, tgt, src, copyIsArray, clone
    for (name in source.value) {
      tgt = target.value[name]
      src = source.value[name]
      if (target === src) { // Prevent never-ending loop
        continue
      }
      if (src && (Value.isDataNode(src) || (copyIsArray = Value.isCollection(src)))) {
        if (copyIsArray) {
          copyIsArray = false
          clone = tgt && Value.isCollection(tgt) ? tgt : {type: 'collection', value: []}
        } else {
          clone = tgt && Value.isDataNode(tgt) ? tgt : Value.dataNode({})
        }
        let res = Value.extend(clone, src, respectNull)
        if (!Value.isNull(res)) {
          target.value[name] = res
        }  
      } else {
        if (!Value.isNull(src) || respectNull) {
          if (Value.isEmpty(src) && tgt && Value.isCollection(tgt)) {
            delete target.value[name]
          } else {
            target.value[name] = src
          }
        }
      }
    }
    return target
  },
  extendFormData: function(target, source) {
    let name, tgt, src, clone
    for (name in source.value) {
      tgt = target.value[name]
      src = source.value[name]
      if (target === src) { // Prevent never-ending loop
        continue
      }
      if (src && Value.isCollection(src)) {
        target.value[name] = Value.v(Value.collectionValue(src).map(item => {
          if (Value.isDataNode(item) || Value.isCollection(item)) {
            return Value.extendFormData(Value.isCollection(item) ? Value.dataNode([], 'collection') : Value.dataNode({}), item)
          } else {
            return item
          }
        }), 'collection')
      } else if (src && Value.isDataNode(src)) {
        clone = tgt && Value.isDataNode(tgt) ? tgt : Value.dataNode({})
        let res = Value.extendFormData(clone, src)
        if (!Value.isNull(res)) { // is it neeeded condition?
          target.value[name] = res
        }
      } else {
        if (!Value.isNull(src)) {
          if (Value.isEmpty(src) && tgt && Value.isCollection(tgt)) {
            delete target.value[name]
          } else {
            target.value[name] = src
          }
        }
      }
    }
    return target
  },
  parseLiteral(source, canBeContextPath = false) {
    if (source.startsWith('{') || source.startsWith('[')) {
      let data = JSON.parse(source)
      return Value.fromJson(data, true)
    } else if (source.startsWith("'")) {
      return Value.v(source.substring(1, source.length - 1), 'string')
    } else if (MC.isNumeric(source)) {
      return Value.v(source, /^-?\d+$/.test(source) ? 'integer' : 'decimal')
    } else if (source == 'true' || source == 'false') {
      return Value.v(source, 'boolean')
    } else if (source == 'null') {
      return Value.v(null)
    } else if (source == 'empty') {
      return Value.v(null, 'string', true)
    } else if (source == 'emptycoll') {
      return Value.v([], 'collection')
    } else if (source.indexOf('(') > 0 && source.endsWith(')')) {
      return Value.v(source.substring(source.indexOf('(') + 1, source.length - 1), source.substring(0, source.indexOf('(')))
    } else {
      if (canBeContextPath) {
        return false
      } else {
        return Value.v(source, 'string')
      }
    }  
  },
  toLiteral: function(value, prettyPrint, inside = false) {
    if (Value.isCollection(value) && !Value.isCollectionNotEmpty(value)) {
      return "emptycoll"
    } else if (Value.isPureNull(value)) {
      return "null"
    } else if (Value.isEmpty(value)) {
      return "empty"
    } else if (Value.isNumber(value) || value.type == 'boolean') {
      return value.value.toString()
    } else if (Value.isCollection(value) || Value.isDataNode(value)) {
      let res = Value.toJson(value, true, true)
      if (prettyPrint !== undefined || Value.isDataNode(value) && MC.isEmptyObject(value.value) && !inside) {
        // root called from function dataToLiteral
        return JSON.stringify(res, null, prettyPrint ? 2 : null)
      } else {
        return res
      }
    } else {
      if (value.type == 'string') {
        return "'" + value.value + "'"
      } else {
        return value.type + '(' + value.value + ')'
      }
    }
  }

}

export {Value}