import React from "react"

import {MC} from './MC.js'
import {Widget} from "./Widget.jsx"
import {Field} from "../modeler/Field.jsx"

class Repeater extends React.Component {

  dragCounter = []
  dragOverTimer = []
  draggedRowIndex = null
  draggedStartIndex = null
  draggedLastIndex = null

  componentDidMount() {
    if (MC.getFieldParamBooleanValue(this.props.data.param, '@reorderingRows') && !MC.isModelerActive(this.props.data)) {
      window.addEventListener('drop', this.dropRowEmbedded, false)
      document.addEventListener('dragover', this.dragOverRow, false)
    }
  }

  componentWillUnmount() {
    if (MC.getFieldParamBooleanValue(this.props.data.param, '@reorderingRows') && !MC.isModelerActive(this.props.data)) {
      window.removeEventListener('drop', this.dropRowEmbedded, false)
      document.removeEventListener('dragover', this.dragOverRow, false)
    }
  }

  buildSubFields(subFields, disabled, readOnly, textMode) {
    let resolution = this.props.resolution
    let hrows = null
    if (Array.isArray(subFields)) {
      let rows = MC.splitFieldsIntoRows(subFields, resolution)
      hrows = []
      for (var i = 0; i < rows.length; i++) {
        var hrow = []
        for (var ii = 0; ii < rows[i].length; ii++) {
          var subField = rows[i][ii]
          let offsetDiv
          var grid = MC.getFieldGrid(subField, resolution)
          if (grid.offset > 0) {
            var cls = "mnc " + MC.getFieldWideClassFromInt(grid.offset) + " wide column field"
            offsetDiv = <div className={cls} key={subField.rbsid + 'gap'}/>
          }
          hrow.push(<Widget key={subField.rbsid} widget={subField} disabled={disabled} readOnly={readOnly} resolution={resolution} offsetDiv={offsetDiv} textMode={textMode}/>)
        }
        hrows.push(<div key={i} className="mnc row">{hrow}</div>)
      }
    }
    return hrows
  }

  dragStartRow = (i, e) => {
    Field.dragStart(this.props.data.fields[0].rows[i], i, e)
  }

  dragStartRowEmbedded = (i, e) => {
    Repeater.draggedComponent = this
    e.stopPropagation()
    this.draggedRowIndex = i
    this.draggedStartIndex = i
  }

  dragOverRow = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

  dragEnterRow = (i, e) => {
    if (MC.dragData) {
      e.stopPropagation()
      e.preventDefault()
      if (MC.dragData.widgetPath == this.props.data.flow.getFormFieldPath(this.props.data) + "/rows*" && MC.dragData.dragIndex == i && this.dragCounter.length == 0) {
        return
      }
      this.dragCounter[i] = this.dragCounter[i] ?  this.dragCounter[i] + 1 : 1
      if (this.dragCounter[i] == 1) {
        let delay = MC.getFieldParamValue(this.props.data.param, '@dragEnterDelay')
        delay = MC.isNumeric(delay) ? parseInt(delay) : 0
        this.dragOverTimer[i] = setTimeout(() => {
          this.dragOverTimer[i] = undefined
          MC.handleEvent(this.props.data.fields[0].rows[i], 'dragenter', null, e, {dragData: MC.dragData})
        }, delay)
      }
    }
  }

  dragEnterRowEmbedded = (i, e) => {
    e.stopPropagation()
    if (this.draggedStartIndex === null) {
      return
    }
    let rows = this.props.data.fields[0].rows
    if (i !== this.draggedRowIndex && i !== this.draggedLastIndex) {
      clearTimeout(this.dragOverTimer)
      let delay = MC.getFieldParamValue(this.props.data.param, '@dragEnterDelay')
      delay = MC.isNumeric(delay) ? parseInt(delay) : 0
      this.dragOverTimer = setTimeout(() => {
        let dRow = rows.splice(this.draggedRowIndex, 1)
        rows.splice(i, 0, dRow[0])
        this.draggedLastIndex = this.draggedRowIndex
        this.draggedRowIndex = i
        this.forceUpdate()
      }, delay)
    } else if (i === this.draggedRowIndex) {
      this.draggedLastIndex = this.draggedRowIndex
    }
  }

  dragLeaveRow = (i, e) => {
    if (MC.dragData) {
      e.stopPropagation()
      if (MC.dragData.widgetPath == this.props.data.flow.getFormFieldPath(this.props.data) + "/rows*" && MC.dragData.dragIndex == i && (this.dragCounter.length == 0 || this.dragCounter.length == 1)) {
        return
      }
      this.dragCounter[i] = this.dragCounter[i] ? this.dragCounter[i] - 1 : 0
      if (this.dragCounter[i] == 0) {
        if (this.dragOverTimer[i]) {
          clearTimeout(this.dragOverTimer[i])
          this.dragOverTimer[i] = undefined
        } else {
          MC.handleEvent(this.props.data.fields[0].rows[i], 'dragleave', null, e, {dragData: MC.dragData})
        }
      }
    }
  }

  dropRow = (i, e) => {
    if (MC.dragData) {
      e.stopPropagation()
      if (MC.dragData.widgetPath == this.props.data.flow.getFormFieldPath(this.props.data) + "/rows*" && MC.dragData.dragIndex == i && this.dragCounter.length == 0) {
        return
      }
      this.dragCounter = []
      MC.handleEvent(this.props.data.fields[0].rows[i], 'drop', null, e, {dragData: MC.dragData})
      MC.clearDragData(null)
    }
  }

  dropRowEmbedded = () => {
    let rpt = Repeater.draggedComponent
    if (!rpt || this.draggedStartIndex === null) {
      return
    }
    Repeater.draggedComponent = undefined
    if (rpt.draggedRowIndex != rpt.draggedStartIndex) {
      MC.ensureIterations(MC.findRootRepeatable(rpt.props.data, rpt.props.data), []) // recalculate iterations
      MC.putFieldParamValue(rpt.props.data.param, '@rowsReordered', true)
      MC.handleEvent(rpt.props.data, 'change')
    }
    rpt.draggedRowIndex = null 
    rpt.draggedStartIndex = null
    rpt.draggedLastIndex = null
  }

  render() {
    let field = this.props.data
    let count = MC.getRowsCount(Array.isArray(field.fields) && field.fields[0] ? field.fields[0] : field)
    let widgets = []
    if (MC.showAtLeastOneIteration(field) && count == 0) {
      count = 1
    }
    let inline = MC.getFieldParamBooleanValue(field.param, '@inline')
    let inlineAuto = MC.getFieldParamBooleanValue(field.param, '@inlineAuto')
    const cls = MC.getFieldParamValue(field.param, '@cssClass')
    let css = MC.styleObjectFromString(MC.getFieldParamValue(field.param, '@css'))
    let draggable = MC.getFieldParamBooleanValue(field.param, '@draggable') && !MC.isModelerActive(field)
    let droppable = MC.getFieldParamBooleanValue(field.param, '@droppable') && !MC.isModelerActive(field)
    let gridStyleMod = MC.isModelerActive(field) ? {margin: 0} : {}
    for (let i = 0; i < count; i++) {
      let key = i
      let disabled = this.props.disabled
      let readOnly = this.props.readOnly
      let textMode = this.props.textMode
      let clsRow
      let cssRow = {}
      let subfields = Array.isArray(field.fields) && field.fields.length > 0 && field.fields[0].fields ? field.fields[0].fields : []
      let draggableRow = draggable
      let droppableRow = droppable
      if (field.fields[0].rows) {
        let row = field.fields[0].rows[i].param
        if (false === MC.getFieldParamBooleanValue(row, '@visible')) {
          continue
        }
        if (false === MC.getFieldParamBooleanValue(row, '@enabled') || false === MC.getFieldParamBooleanValue(row, '@permitted')) {
          disabled = true
        }
        if (true === MC.getFieldParamBooleanValue(row, '@readonly')) {
          readOnly = true
        }
        if (MC.getFieldParamBooleanValue(row, '@textmode') !== null) {
          textMode = MC.getFieldParamBooleanValue(row, '@textmode')
        }
        if (MC.getFieldParamBooleanValue(row, '@draggable') !== null) {
          draggableRow = MC.getFieldParamBooleanValue(row, '@draggable')
        }
        if (MC.getFieldParamBooleanValue(row, '@droppable') !== null) {
          droppableRow = MC.getFieldParamBooleanValue(row, '@droppable')
        }
        subfields = field.fields[0].rows[i].fields
        clsRow = MC.getFieldParamValue(row, '@cssClass')
        cssRow = MC.styleObjectFromString(MC.getFieldParamValue(row, '@css'))
        if (MC.getFieldParamValue(row, '@id') !== null) {
          key = MC.getFieldParamValue(row, '@id')
        }
        row['@position'] = i
      }
      let resolution = this.props.resolution
      let wideClass = "twelve"
      if (inline) {
        let grid = MC.getFieldGrid(field, resolution)
        wideClass = MC.getFieldWideClassFromInt(grid.columns)
      } else if (inlineAuto) {
        wideClass = "auto-inline"
      }
      let reorderingRows = MC.getFieldParamBooleanValue(field.param, '@reorderingRows')
      let className = MC.classes("mnc", wideClass, "wide column", cls, clsRow, {'curgrab': draggableRow || reorderingRows})
      let dragEnterRow = null
      let dragStartRow = null
      let dragOverRow = null
      let dragLeaveRow = null
      let dropRow = null
      let dragEndRow = null
      if (!MC.isModelerActive(field)) {
        if (draggableRow) {
          dragStartRow = this.dragStartRow.bind(this, i)
          dragEndRow = MC.clearDragData
        } else if (reorderingRows) {
          dragStartRow = this.dragStartRowEmbedded.bind(this, i)
        }
        if (droppableRow) {
          dragOverRow = this.dragOverRow
        dragEnterRow = this.dragEnterRow.bind(this, i)
        dragEnterRow = this.dragEnterRow.bind(this, i)
        dragOverRow = this.dragOverRow
          dragEnterRow = this.dragEnterRow.bind(this, i)
        dragOverRow = this.dragOverRow
          dragLeaveRow = this.dragLeaveRow.bind(this, i)
          dropRow = this.dropRow.bind(this, i)
        } else if (reorderingRows) {
          dragOverRow = this.dragOverRow
          dragEnterRow = this.dragEnterRowEmbedded.bind(this, i)
          dropRow = this.dropRowEmbedded
        }
      }
      widgets.push(<div key={key} className={className} style={{...css, ...cssRow}} draggable={draggableRow || reorderingRows} onDragStart={dragStartRow} onDragEnter={dragEnterRow} onDragLeave={dragLeaveRow} onDragOver={dragOverRow} onDrop={dropRow} onDragEnd={dragEndRow}><div className="mnc twelve column grid widgetContainer" style={gridStyleMod}>{this.buildSubFields(subfields, disabled, readOnly, textMode)}</div></div>)
    }
    return (
    <div className="mnc twelve column grid widgetContainer" style={gridStyleMod} data-widget-i-name={field.id}>
      {widgets}
    </div>)
  }

}

export {Repeater}