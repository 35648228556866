import React from "react"

import {EditableLabel} from "../modeler/EditableLabel.jsx"

import Checkbox from "./semantic-widgets/Checkbox.jsx"
import {Widget} from "./Widget.jsx"
import TableFilterCell from "./TableFilterCell.jsx"
import {MC} from './MC.js'
import FixedSroll from "./FixedScroll.js"
import {Field} from "../modeler/Field.jsx"
import tt from "./tooltip/tooltip.js"

class Table extends React.Component {

  state = {}
  tableRef = React.createRef()
  wrapperRef = React.createRef()
  draggedRowIndex = null
  draggedStartIndex = null
  draggedLastIndex = null
  dragCounter = []
  dragOverTimer = []
  // COMPATIBILITY FLAG
  tdAsWrapper = this.props.data.flow.getCfgParameter('mini:tdAsWrapper') == 'true'
  inLabelSelector = <label key="chlabel">&nbsp;</label>

  componentDidMount() {
    this.initDefaultParameters()
    this.setState({width: this.tableRef.current.offsetWidth})
    this.ensureResizeHandlers()
    let escape = MC.getFieldParamBooleanValue(this.props.data.param, '@escapeTooltipHtml')
    tt(this.tableRef.current.querySelectorAll('[data-tt-content]'), {allowHTML: !escape})
    if (MC.getFieldParamBooleanValue(this.props.data.param, 'settings/@scrollableHorizontal')) {
      this.fixedScroll = new FixedSroll()
      this.fixedScroll.init(this.wrapperRef.current)
    }
  }

  componentDidUpdate() {
    this.ensureResizeHandlers()
    this.initDefaultParameters()
    if (Math.abs(this.state.width - this.tableRef.current.offsetWidth) > 2) {
      this.setState({width: this.tableRef.current.offsetWidth})
    }
    if (MC.getFieldParamBooleanValue(this.props.data.param, 'settings/@scrollableHorizontal')) {
      if (this.fixedScroll) {
        this.fixedScroll.update()
      } else {
        this.fixedScroll = new FixedSroll()
        this.fixedScroll.init(this.wrapperRef.current)
      }
    } else {
      if (this.fixedScroll) {
        this.fixedScroll.destroy()
        this.fixedScroll = null
      }  
    }
    if (this.toFocus) {
      let row = this.tableRef.current.querySelector("tr[data-index='" + this.toFocus.dataIndex + "']")
      if (row) {
        let field = row.querySelector("[data-widget-id='" + this.toFocus.fieldId + "']")
        if (field) {
          field.focus()
        }
      }
      delete this.toFocus 
    }
  }

  componentWillUnmount() {
    this.removeResizeHandlers()
    if (MC.getFieldParamBooleanValue(this.props.data.param, 'settings/@scrollableHorizontal') && this.fixedScroll) {
      this.fixedScroll.destroy()
      this.fixedScroll = null
    }
  }

  ensureResizeHandlers() {
    if (MC.getFieldParamBooleanValue(this.props.data.param, 'settings/@resizingColumns')) {
      if (!this.reziseHandlersActive) {
        document.addEventListener('mousemove', this.mouseMove)
        document.addEventListener('mouseup', this.mouseUp)
        this.reziseHandlersActive = true
      }
    } else {
      this.removeResizeHandlers()
    }
  }

  removeResizeHandlers() {
    if (this.reziseHandlersActive) {
      document.removeEventListener('mousemove', this.mouseMove)
      document.removeEventListener('mouseup', this.mouseUp)
      this.reziseHandlersActive = false
    }
  }  

  initDefaultParameters() {
    let param = this.props.data.param
    if (MC.isNull(MC.getFieldParamValue(param, 'settings/@rowsReordered'))) {
      MC.putFieldParamValue(param, 'settings/@rowsReordered', false)
    }
    if (MC.isNull(MC.getFieldParamValue(param, 'settings/@columnsReordered'))) {
      MC.putFieldParamValue(param, 'settings/@columnsReordered', false)
    }
    if (MC.isNull(MC.getFieldParamValue(param, 'settings/@columnsResized'))) {
      MC.putFieldParamValue(param, 'settings/@columnsResized', false)
    }
  }

  enterRowDragIcon = (e) => {
    MC.findAncestorEl(e.currentTarget, 'tr').draggable = true
  }

  leaveRowDragIcon = (e) => {
    MC.findAncestorEl(e.currentTarget, 'tr').draggable = false
  }

  buildTableRow(field, subFields, irow, disabled, readOnly, columns, selector, selected, textMode) {
    let dragIcon = MC.getFieldParamBooleanValue(field.param, 'settings/@reorderingRows') ? <i className="icon ellipsis vertical drag-ico" onMouseEnter={this.enterRowDragIcon} onMouseLeave={this.leaveRowDragIcon}/> : null
    let selCheckbox = selector == 'left' ? <Checkbox checked={selected} onChange={this.handleSelectRow.bind(this, irow)} title={MC.formatMessage("selectRow", field.flow.reactFlow().props.mconf.lang)} label={this.inLabelSelector}/> : null
    let row = []
    if (selCheckbox || dragIcon) {
      row.push(<td key="select" className="selector">{dragIcon}{selCheckbox}</td>)
    }
    if (Array.isArray(subFields)) {
      for (let i = 0; i < field.fields[0].fields.length; i++) {
        let subField = subFields.find(sfield => sfield.rbsid == field.fields[0].fields[i].rbsid)
        let halign =  MC.getFieldParamValue(subField.param, '@horizontalAlignment')
        let cls = MC.classes(MC.getFieldParamValue(subField.param, '@cssClass'), {'right aligned': halign == 'right', 'center aligned': halign == 'center', 
                    'ellipsis':  MC.getFieldParamBooleanValue(columns[subField.id], '@ellipsisText'), 'collapsing': MC.getFieldParamBooleanValue(columns[subField.id], '@collapsing'),
                    'nowrap':  MC.getFieldParamBooleanValue(columns[subField.id], '@nowrap')})          
        let layoutVisible = MC.getFieldParamValue(columns[subField.id], '@layoutVisible')
        if (!MC.isNull(layoutVisible) && layoutVisible !== '') {
          layoutVisible = layoutVisible.split(' ')
        } else {
          layoutVisible = false
        }
        let columnVisible = MC.getFieldParamBooleanValue(columns[subField.id], '@visible')
        if(subField.flow.modelerReact && subField.flow.modelerReact.state.ghostMode) {
          columnVisible = true
        }
        if (!columnVisible || Array.isArray(layoutVisible) && !MC.isNull(this.props.resolution) && layoutVisible.indexOf(this.props.resolution) == -1) {
          continue
        }
        let visible = MC.getFieldParamBooleanValue(subField.param, '@visible')
        if (subField.flow.modelerReact && subField.flow.modelerReact.state.ghostMode) {
          visible = true
        }
        let inline = {}
        const percentWidth = MC.getFieldParamValue(columns[subField.id], '@percentWidth')
        if (percentWidth && MC.isNumeric(percentWidth) && this.state.width) {
          if (window.innerWidth >= 768) {
            let width = this.state.width * (parseInt(percentWidth)/100) + 'px'
            inline.width = width
            inline.maxWidth = width
          }
        }
        const minWidth = MC.getFieldParamValue(columns[subField.id], '@minWidth')
        if (minWidth && MC.isNumeric(minWidth) && this.state.width) {
          inline.minWidth = minWidth + 'px'
        }
        let css = MC.styleObjectFromString(MC.getFieldParamValue(subField.param, '@css'))
        if (visible) {
          if (textMode && Widget.hasTextMode(subField)) {
            if (MC.getFieldParamBooleanValue(subField.param, '@focused')) {
              MC.putFieldParamValue(subField.param, '@focused', false)
              this.toFocus = {dataIndex: irow, fieldId: subField.rbsid}
            }
            if (this.tdAsWrapper) {
              let tdAsWrappercls = MC.classes(MC.getFieldParamValue(subField.param, '@cssClassField'), {'right aligned': halign == 'right', 'center aligned': halign == 'center', 
              'ellipsis':  MC.getFieldParamBooleanValue(columns[subField.id], '@ellipsisText'), 'collapsing': MC.getFieldParamBooleanValue(columns[subField.id], '@collapsing'),
              'nowrap':  MC.getFieldParamBooleanValue(columns[subField.id], '@nowrap')}, 'textmode', 'widget widget-' + subField.widget)  
              row.push(<td className={tdAsWrappercls} key={i} data-widget-id={subField.rbsid} data-widget-name={subField.id} tabIndex="-1" style={{...MC.styleObjectFromString(MC.getFieldParamValue(subField.param, '@cssField')), ...inline}}>{Widget.getTextMode(subField, Widget.getValue(subField))}</td>)
            } else {
              let clsField = MC.classes(MC.getFieldParamValue(subField.param, '@cssClassField'), 'textmode', 'widget widget-' + subField.widget)
              row.push(<td className={cls} key={i} style={{...css, ...inline}}><div className={clsField} style={MC.styleObjectFromString(MC.getFieldParamValue(subField.param, '@cssField'))} data-widget-id={subField.rbsid} data-widget-name={subField.id} tabIndex="-1">{Widget.getTextMode(subField, Widget.getValue(subField))}</div></td>)
            }
          } else {
            if (this.tdAsWrapper) {
              let tdAsWrappercls = MC.classes(MC.getFieldParamValue(subField.param, '@cssClassField'), {'right aligned': halign == 'right', 'center aligned': halign == 'center', 
              'ellipsis':  MC.getFieldParamBooleanValue(columns[subField.id], '@ellipsisText'), 'collapsing': MC.getFieldParamBooleanValue(columns[subField.id], '@collapsing'),
              'nowrap':  MC.getFieldParamBooleanValue(columns[subField.id], '@nowrap')})  
              row.push(<Widget key={i} widget={subField} resolution={this.props.resolution} disabled={disabled} readOnly={readOnly} textMode={textMode} tdAsWrapper={this.tdAsWrapper} 
                className={tdAsWrappercls} style={{...MC.styleObjectFromString(MC.getFieldParamValue(subField.param, '@cssField')), ...inline}}/>)
            } else {
              row.push(<td className={cls} key={i} style={{...css, ...inline}}><Widget key={subField.rbsid} widget={subField} resolution={this.props.resolution} disabled={disabled} readOnly={readOnly} textMode={textMode}/></td>)
            }
          }
        } else {
          row.push(<td className={cls} style={css} key={i}>&nbsp;</td>)
        }
      }
    }
    if (selector == 'right') {
      row.push(<td key="selectr" className="selector"><Checkbox checked={selected} onChange={this.handleSelectRow.bind(this, irow)} title={MC.formatMessage("selectRow", field.flow.reactFlow().props.mconf.lang)} label={this.inLabelSelector}/></td>)
    }
    return row
  }

  onSort = (column) => {
    // clear old sort setting
    if (this.props.data.param.columns) {
      for (const colKey in this.props.data.param.columns) {
        if (this.props.data.param.columns[colKey] == column) {
          continue
        }  
        this.props.data.param.columns[colKey]['@sorted'] = false
        this.props.data.param.columns[colKey]['@ascending'] = true
      }
    }
    if (!column['@sorted']) {
      column['@sorted'] = true
      column['@ascending'] = true
      MC.putFieldParamValue(this.props.data.param, 'settings/@sortAscending', true)
    } else if (column['@ascending']) {
      column['@ascending'] = false
      MC.putFieldParamValue(this.props.data.param, 'settings/@sortAscending', false) 
    } else {
      column['@sorted'] = false
      MC.putFieldParamValue(this.props.data.param, 'settings/@sortColumn', null)
      MC.putFieldParamValue(this.props.data.param, 'settings/@sortAscending', null)
    }
    this.props.data.flow.runLazyUpdate(this.props.data)
  }

  handleSelectAll = (e, d) => {
    MC.putFieldParamValue(this.props.data.param, 'settings/@selectedAll', d.checked)
    if (Array.isArray(this.props.data.fields[0].rows) && this.props.data.fields[0].rows.length > 0) {
      for (let row of this.props.data.fields[0].rows) {
        MC.putFieldParamValue(row.param, '@selected', d.checked)
      }
    }
    this.forceUpdate()
    MC.handleEvent(this.props.data, 'change')
  }

  handleSelectRow(i, e, data) {
    MC.putFieldParamValue(this.props.data.fields[0].rows[i].param, '@selected', data.checked)
    this.forceUpdate()
    MC.handleEvent(this.props.data, 'change')
  }

  dragStartRow = (i, e) => {
    if (MC.getFieldParamBooleanValue(this.props.data.param, 'settings/@reorderingRows')) {
      Table.draggedComponent = this
      e.stopPropagation()
      this.draggedRowIndex = i
      this.draggedStartIndex = i
    }  
  }

  dragStartRowGeneric = (i, e) => {
    Field.dragStart(this.props.data.fields[0].rows[i], i, e)
  }  

  dragOverRow = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

  dragEnterRow = (i, e) => {
    e.stopPropagation()
    if (this.draggedStartIndex === null) {
      return
    }
    let rows = this.props.data.fields[0].rows
    if (i !== this.draggedRowIndex && i !== this.draggedLastIndex) {
      clearTimeout(this.dragOverTimer)
      let delay = MC.getFieldParamValue(this.props.data.param, '@dragEnterDelay')
      delay = MC.isNumeric(delay) ? parseInt(delay) : 0
      this.dragOverTimer = setTimeout(() => {
        let dRow = rows.splice(this.draggedRowIndex, 1)
        rows.splice(i, 0, dRow[0])
        this.draggedLastIndex = this.draggedRowIndex
        this.draggedRowIndex = i
        this.forceUpdate()
      }, delay)
    } else if (i === this.draggedRowIndex) {
      this.draggedLastIndex = this.draggedRowIndex
    }
  }

  dragEnterRowGeneric = (i, e) => {
    if (MC.dragData) {
      e.stopPropagation()
      e.preventDefault()
      if (MC.dragData.widgetPath == this.props.data.flow.getFormFieldPath(this.props.data) + "/rows*" && MC.dragData.dragIndex == i && this.dragCounter.length == 0) {
        return
      }
      this.dragCounter[i] = this.dragCounter[i] ?  this.dragCounter[i] + 1 : 1
      if (this.dragCounter[i] == 1) {
        let delay = MC.getFieldParamValue(this.props.data.param, '@dragEnterDelay')
        delay = MC.isNumeric(delay) ? parseInt(delay) : 0
        this.dragOverTimer[i] = setTimeout(() => {
          this.dragOverTimer[i] = undefined
          MC.handleEvent(this.props.data.fields[0].rows[i], 'dragenter', null, e, {dragData: MC.dragData})
        }, delay)
      }
    }
  }

  dragLeaveRow = (i, e) => {
    if (MC.dragData) {
      e.stopPropagation()
      if (MC.dragData.widgetPath == this.props.data.flow.getFormFieldPath(this.props.data) + "/rows*" && MC.dragData.dragIndex == i && (this.dragCounter.length == 0 || this.dragCounter.length == 1)) {
        return
      }
      this.dragCounter[i] = this.dragCounter[i] ? this.dragCounter[i] - 1 : 0
      if (this.dragCounter[i] == 0) {
        if (this.dragOverTimer[i]) {
          clearTimeout(this.dragOverTimer[i])
          this.dragOverTimer[i] = undefined
        } else {
          MC.handleEvent(this.props.data.fields[0].rows[i], 'dragleave', null, e, {dragData: MC.dragData})
        }
      }
    }
  }

  dropRow = () => {
    let tbl = Table.draggedComponent
    if (!tbl || this.draggedStartIndex === null) {
      return
    }
    Table.draggedComponent = undefined
    if (tbl.draggedRowIndex != tbl.draggedStartIndex) {
      MC.ensureIterations(MC.findRootRepeatable(tbl.props.data, tbl.props.data), []) // recalculate iterations
      MC.putFieldParamValue(tbl.props.data.param, 'settings/@rowsReordered', true)
      MC.handleEvent(tbl.props.data, 'change')
    }
    tbl.draggedRowIndex = null 
    tbl.draggedStartIndex = null
    tbl.draggedLastIndex = null
  }

  dropRowGeneric = (i, e) => {
    if (MC.dragData) {
      e.stopPropagation()
      if (MC.dragData.widgetPath == this.props.data.flow.getFormFieldPath(this.props.data) + "/rows*" && MC.dragData.dragIndex == i && this.dragCounter.length == 0) {
        return
      }
      this.dragCounter = []
      MC.handleEvent(this.props.data.fields[0].rows[i], 'drop', null, e, {dragData: MC.dragData})
      MC.clearDragData(null)
    }
  }  

  dragStartColumn = (i, e) => {
    if (MC.getFieldParamBooleanValue(this.props.data.param, 'settings/@reorderingColumns')) {
      e.stopPropagation()
      this.draggedColumnIndex = i
      this.draggedStartColumnIndex = i
    }
  }

  dragOverColumn = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

  dragEnterColumn = (i, e) => {
    if (MC.isNull(this.draggedStartColumnIndex) || e.currentTarget.draggable) {
      return
    }
    e.currentTarget.dataset.cDragEnterCounter = e.currentTarget.dataset.cDragEnterCounter ? Number(e.currentTarget.dataset.cDragEnterCounter) + 1 : 1
    this.draggedColumnIndex = i
    let field = this.props.data
    this.lastOveredColumn = e.currentTarget
    if (i != this.draggedStartColumnIndex && field.param.columns[field.fields[0].fields[this.draggedStartColumnIndex].id]['@group'] == field.param.columns[field.fields[0].fields[i].id]['@group']) {
      e.currentTarget.classList.add("candrop")
      if (i > this.draggedStartColumnIndex) {
        e.currentTarget.classList.add("rightdrop")
      }
    } else if (i != this.draggedStartColumnIndex && field.param.columns[field.fields[0].fields[this.draggedStartColumnIndex].id]['@group'] != field.param.columns[field.fields[0].fields[i].id]['@group']) {
      e.currentTarget.classList.add("cantdrop")
    }
  }

  dragLeaveColumn = (e) => {
    if (MC.isNull(this.draggedStartColumnIndex) || e.currentTarget.draggable) {
      return
    }
    e.currentTarget.dataset.cDragEnterCounter = Number(e.currentTarget.dataset.cDragEnterCounter) - 1
    if (e.currentTarget.dataset.cDragEnterCounter == 0) {
      e.currentTarget.classList.remove("candrop", "rightdrop", "cantdrop")
    }
  }  

  dropColumn = () => {
    if (MC.isNull(this.draggedStartColumnIndex)) {
      return
    }
    let columns = this.props.data.fields[0].fields
    if (this.draggedStartColumnIndex != this.draggedColumnIndex && this.props.data.param.columns[columns[this.draggedStartColumnIndex].id]['@group'] == this.props.data.param.columns[columns[this.draggedColumnIndex].id]['@group']) {
      let dColumn = columns.splice(this.draggedStartColumnIndex, 1)
      columns.splice(this.draggedColumnIndex, 0, dColumn[0])
      let oldColumns = this.props.data.param.columns
      let newColumns = {}
      for (let col of columns) {
        newColumns[col.id] = oldColumns[col.id]
      }
      this.props.data.param.columns = newColumns
      this.forceUpdate()
      MC.putFieldParamValue(this.props.data.param, 'settings/@columnsReordered', true)
      MC.handleEvent(this.props.data, 'change')
    }
    this.draggedColumnIndex = null
    this.draggedStartColumnIndex = null
    if (this.lastOveredColumn) {
      this.lastOveredColumn.dataset.cDragEnterCounter = 0
      this.lastOveredColumn.classList.remove("candrop", "rightdrop", "cantdrop")
    }
  }

  dragStartGroup = (i, e) => {
    if (MC.getFieldParamBooleanValue(this.props.data.param, 'settings/@reorderingColumns')) {
      e.stopPropagation()
      this.draggedGroupIndex = i
      this.draggedGroupIndexStart = i
    }
  }

  dragOverGroup = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

  dragEnterGroup = (i, e) => {
    if (MC.isNull(this.draggedGroupIndexStart) || e.currentTarget.draggable || e.currentTarget.classList.contains('group-empty')) {
      return
    }
    e.currentTarget.dataset.cDragEnterCounter = e.currentTarget.dataset.cDragEnterCounter ? Number(e.currentTarget.dataset.cDragEnterCounter) + 1 : 1
    this.draggedGroupIndex = i
    this.lastOveredGroup = e.currentTarget
    if (i != this.draggedGroupIndexStart) {
      e.currentTarget.classList.add("candrop")
      if (i > this.draggedGroupIndexStart) {
        e.currentTarget.classList.add("rightdrop")
      }
    }
  }

  dragLeaveGroup = (e) => {
    if (MC.isNull(this.draggedGroupIndexStart) || e.currentTarget.draggable || e.currentTarget.classList.contains('group-empty')) {
      return
    }
    e.currentTarget.dataset.cDragEnterCounter = Number(e.currentTarget.dataset.cDragEnterCounter) - 1
    if (e.currentTarget.dataset.cDragEnterCounter == 0) {
      e.currentTarget.classList.remove("candrop", "rightdrop")
    }
  }  

  dropGroup = (e) => {
    if (MC.isNull(this.draggedGroupIndexStart) || e.currentTarget.draggable || e.currentTarget.classList.contains('group-empty')) {
      return
    }
    let columnsDef = this.props.data.param.columns
    let columns = this.props.data.fields[0].fields
    if (this.draggedGroupIndex != this.draggedGroupIndexStart) {
      let endSourceIndex = this.draggedGroupIndexStart - 1
      let startSourceIndex = endSourceIndex
      for (let i = endSourceIndex; i>=0; i--) {
        startSourceIndex = i
        if (columnsDef[columns[endSourceIndex].id]['@group'] != columnsDef[columns[i].id]['@group']) {
          startSourceIndex = i+1
          break
        }
      }
      let targetIndex
      if (this.draggedGroupIndex > this.draggedGroupIndexStart) {
        targetIndex = this.draggedGroupIndex - (endSourceIndex-startSourceIndex) - 1
      } else {
        let endTargetIndex = this.draggedGroupIndex - 1
        let targetGroup = columnsDef[columns[endTargetIndex].id]['@group'] 
        targetIndex = endTargetIndex
        for (let i = endTargetIndex; i>=0; i--) {
          targetIndex = i
          if (targetGroup != columnsDef[columns[i].id]['@group']) {
            targetIndex = i+1
            break
          }
        }
      }
      let dColumns = columns.splice(startSourceIndex, endSourceIndex-startSourceIndex+1)
      columns.splice(targetIndex, 0, ...dColumns)
      let oldColumns = this.props.data.param.columns
      let newColumns = {}
      for (let col of columns) {
        newColumns[col.id] = oldColumns[col.id]
      }
      this.props.data.param.columns = newColumns
      this.forceUpdate()
      MC.putFieldParamValue(this.props.data.param, 'settings/@columnsReordered', true)
      MC.handleEvent(this.props.data, 'change')
    }
    this.draggedGroupIndex = null
    this.draggedGroupIndexStart = null
    if (this.lastOveredGroup) {
      this.lastOveredGroup.dataset.cDragEnterCounter = 0
      this.lastOveredGroup.classList.remove("candrop", "rightdrop")
    }
  }

  enterColumnDragIcon = (e) => {
    MC.findAncestorEl(e.currentTarget, 'th').draggable = true
  }

  leaveColumnDragIcon = (e) => {
    MC.findAncestorEl(e.currentTarget, 'th').draggable = false
  }

  enterGroupDragIcon = (e) => {
    MC.findAncestorEl(e.currentTarget, 'th').draggable = true
  }

  leaveGroupDragIcon = (e) => {
    MC.findAncestorEl(e.currentTarget, 'th').draggable = false
  }

  onDragEndColumn = () => {
    this.draggedStartColumnIndex = null
    this.draggedColumnIndex = null
  }

  onDragEndGroup = () => {
    this.draggedGroupIndex = null
    this.draggedGroupIndexStart = null
  }

  mouseDownResizer = (column, e) => {
    this.column = column
    this.x = e.clientX
    this.colEl = MC.findAncestorEl(e.target, 'TH')
    let styles = window.getComputedStyle(this.colEl)
    this.w = parseInt(styles.width, 10)
  }

  mouseMove = (e) => {
    if (this.colEl) {
      let nwidth = this.w + e.clientX - this.x
      this.colEl.style.width = `${nwidth}px`
      this.colEl.style.minWidth = `${nwidth}px`
      this.colEl.style.maxWidth = `${nwidth}px`
      this.column['@width'] = nwidth
    }
  }

  mouseUp = () => {
    if (this.colEl) {
      delete this.colEl
      delete this.column
      delete this.x
      delete this.w
      MC.putFieldParamValue(this.props.data.param, 'settings/@columnsResized', true)
    }
  }

  render() {
    let field = this.props.data
    let headAlign =  MC.getFieldParamValue(field.param, 'settings/@headAlign')
    let selector = MC.getFieldParamValue(field.param, 'settings/@selector')
    let selectedAll = MC.getFieldParamBooleanValue(field.param, 'settings/@selectedAll') || false
    let hideHeader = MC.getFieldParamBooleanValue(field.param, 'settings/@hideHeader')
    let resizingColumns = MC.getFieldParamBooleanValue(field.param, 'settings/@resizingColumns')
    let groupsHeader = null
    let header = null
    let isTableSortable = false
    let lastGroup = ''
    let lastGroupEnd = 0
    let columnsToRender = []
    if (Array.isArray(field.fields) && field.fields.length > 0 && field.fields[0].fields) {
      for (let i = 0; i < field.fields[0].fields.length; i++) {
        let column = field.param.columns[field.fields[0].fields[i].id]
        if (column) {
          let layoutVisible = MC.getFieldParamValue(column, '@layoutVisible')
          if (!MC.isNull(layoutVisible) && layoutVisible !== '') {
            layoutVisible = layoutVisible.split(' ')
          } else {
            layoutVisible = false
          }
          let columnVisible = MC.getFieldParamBooleanValue(column, '@visible')
          if (field.flow.modelerReact && field.flow.modelerReact.state.ghostMode) {
            columnVisible = true
          }
          if (!columnVisible || Array.isArray(layoutVisible) && !MC.isNull(this.props.resolution) && layoutVisible.indexOf(this.props.resolution) == -1) {
            continue
          }
          columnsToRender.push({field: field.fields[0].fields[i], column: column})
        }
      }
    }
    if (!hideHeader) {
      let resizer = null
      header = []
      groupsHeader = []
      if (selector == 'left') {
        let dragIcon = MC.getFieldParamBooleanValue(field.param, 'settings/@reorderingRows') ? <i className="icon ellipsis vertical drag-ico"/> : null
        header.push(<th key="select" className="selector">{dragIcon}<Checkbox checked={selectedAll} onChange={this.handleSelectAll} 
                        title={MC.formatMessage("selectAllRows", field.flow.reactFlow().props.mconf.lang)} label={this.inLabelSelector}/></th>)
      } else if (MC.getFieldParamBooleanValue(field.param, 'settings/@reorderingRows')) {
        header.push(<th key="space">&nbsp;</th>)
      }
      for (let i=0; i<columnsToRender.length; i++) {
        let column = columnsToRender[i].column
        let columnField = columnsToRender[i].field
        let sortable = MC.getFieldParamBooleanValue(column, '@sortable') === true
        let title = MC.getFieldParamValue(column, '@title')
        let halign =  MC.getFieldParamValue(columnField.param, '@horizontalAlignment')
        let cls = MC.classes({'right aligned': halign == 'right', 'center aligned': halign == 'center'})
        if (headAlign) {
          cls = MC.classes({'right aligned': headAlign == 'right', 'center aligned': headAlign == 'center'})
        }
        let inline = {}
        let onclick = !MC.isModelerActive(field) ? this.onSort.bind(this, column) : null
        if (!sortable) {
          inline.cursor = 'auto'
          onclick = null
        } else {
          isTableSortable = true
          if (column['@sorted']) {
            if (column['@ascending']) {
              cls += ' sorted ascending'
            } else {
              cls += ' sorted descending'
            }
          } else {
            cls += ' sorted sortable'
          }
        }  
        if (MC.isModelerActive(field)) {
          title = <EditableLabel field={columnField} widget={title} path={["param", "columns", "@title"]}/>
        }
        const percentWidth = MC.getFieldParamValue(column, '@percentWidth')
        if (percentWidth && MC.isNumeric(percentWidth) && this.state.width) {
          if (window.innerWidth >= 768) {
            inline.width = this.state.width * (parseInt(percentWidth)/100) + 'px'
          }
        }
        const minWidth = MC.getFieldParamValue(column, '@minWidth')
        if (minWidth && MC.isNumeric(minWidth) && this.state.width) {
          inline.minWidth = minWidth + 'px'
        }
        let width = MC.getFieldParamValue(column, '@width')
        if (width) {
          width = MC.isNumeric(width) ? width + 'px' : width
          inline.width = width
          inline.minWidth = width
          inline.maxWidth = width
        }
        let dragIcon
        if (MC.getFieldParamBooleanValue(field.param, 'settings/@reorderingColumns')) {
          dragIcon = <div className="inline-div"><i className="icon ellipsis vertical drag-ico" onMouseEnter={this.enterColumnDragIcon} onMouseLeave={this.leaveColumnDragIcon}/></div>
        }
        let group = MC.getFieldParamValue(column, '@group')
        cls = MC.classes({'collapsing': MC.getFieldParamBooleanValue(column, '@collapsing'), 'nowrap': MC.getFieldParamBooleanValue(column, '@nowrap')}, cls)
        if (resizingColumns) {
          resizer = <div className="resizer" onMouseDown={this.mouseDownResizer.bind(this, column)}></div>
        }
        let tooltip = MC.getFieldParamValue(column, '@tooltip')
        if (MC.getFieldParamBooleanValue(column, '@escapeTitleHtml') || MC.isModelerActive(field)) {
          header.push(<th onDragStart={this.dragStartColumn.bind(this, i)} onDragOver={this.dragOverColumn} onDragEnter={this.dragEnterColumn.bind(this, i)} onDragLeave={this.dragLeaveColumn} 
                          onDrop={this.dropColumn} onDragEnd={this.onDragEndColumn} key={columnField.rbsid} className={cls} style={inline} onClick={onclick}>{dragIcon}<div className="inline-div" 
                          data-tt-content={tooltip}>{title}</div>{resizer}</th>)
        } else {
          header.push(<th onDragStart={this.dragStartColumn.bind(this, i)} onDragOver={this.dragOverColumn} onDragEnter={this.dragEnterColumn.bind(this, i)} onDragLeave={this.dragLeaveColumn} 
                          onDrop={this.dropColumn} onDragEnd={this.onDragEndColumn} key={columnField.rbsid} className={cls} style={inline} onClick={onclick} >{dragIcon}<div className="inline-div" 
                          data-tt-content={tooltip} dangerouslySetInnerHTML={{__html: MC.customHtml(title)}}/>{resizer}</th>)
        }
        if ((group || groupsHeader.length > 0 || lastGroup) && (lastGroup !== group || i == columnsToRender.length-1)) {
          if (lastGroup !== group) {
            let dragIcon
            if (MC.getFieldParamBooleanValue(field.param, 'settings/@reorderingColumns') && lastGroup) {
              dragIcon = <div className="inline-div"><i className="icon ellipsis vertical drag-ico" onMouseEnter={this.enterGroupDragIcon} onMouseLeave={this.leaveGroupDragIcon}/></div>
            }
            if (i != 0) {
              groupsHeader.push(<th key={i} colSpan={i-lastGroupEnd} className={MC.classes({'group': lastGroup, 'group-empty': !lastGroup})} onDragStart={this.dragStartGroup.bind(this, i)} 
                                    onDragOver={this.dragOverGroup} onDragEnter={this.dragEnterGroup.bind(this, i)} onDragLeave={this.dragLeaveGroup} onDrop={this.dropGroup} 
                                    onDragEnd={this.onDragEndGroup}>{dragIcon}<div className="inline-div">{lastGroup}</div></th>)

            }
            lastGroupEnd = i
            lastGroup = group
          } 
          if (i == columnsToRender.length-1) {
            let dragIcon
            if (MC.getFieldParamBooleanValue(field.param, 'settings/@reorderingColumns') && group) {
              dragIcon = <div className="inline-div"><i className="icon ellipsis vertical drag-ico" onMouseEnter={this.enterGroupDragIcon} onMouseLeave={this.leaveGroupDragIcon}/></div>
            }
            groupsHeader.push(<th key="l" colSpan={i-lastGroupEnd+1} className={MC.classes({'group': group, 'group-empty': !group})} onDragStart={this.dragStartGroup.bind(this, i+1)} 
                                  onDragOver={this.dragOverGroup} onDragEnter={this.dragEnterGroup.bind(this, i+1)} onDragLeave={this.dragLeaveGroup} onDrop={this.dropGroup} 
                                  onDragEnd={this.onDragEndGroup}>{dragIcon}<div className="inline-div">{lastGroup}</div></th>)
          } 
        }
      }
      if ((selector == 'left' || MC.getFieldParamBooleanValue(field.param, 'settings/@reorderingRows')) && groupsHeader.length > 0) {
        groupsHeader.unshift(<th key="selector" className="group-empty">&nbsp;</th>)
      }
      if (selector == 'right' && groupsHeader.length > 0) {
        groupsHeader.push(<th key="selectorr" className="group-empty">&nbsp;</th>)
      }
      if (selector == 'right') {
        header.push(<th key="select" className="selector"><Checkbox checked={selectedAll} onChange={this.handleSelectAll} title={MC.formatMessage("selectAllRows", field.flow.reactFlow().props.mconf.lang)} label={this.inLabelSelector}/></th>)
      }
      header = header.length > 0 ? <tr className="header-row">{header}</tr> : null
      groupsHeader = groupsHeader.length > 0 ? <tr>{groupsHeader}</tr> : null
    }
    let filter = null
    const showFilterRow = MC.getFieldParamBooleanValue(field.param, 'settings/@showFilterRow')
    if (showFilterRow) {
      filter = []
      if (selector == 'left' || MC.getFieldParamBooleanValue(field.param, 'settings/@reorderingRows')) {
        filter.push(<th key="select">&nbsp;</th>)
      }
      if (Array.isArray(field.fields) && field.fields.length > 0 && field.fields[0].fields) {
        for (let i=0; i < field.fields[0].fields.length; i++) {
          let colfield = field.fields[0].fields[i]
          let fcolumn = field.param.columns[colfield.id]
          if (fcolumn) {
            let layoutVisible = MC.getFieldParamValue(fcolumn, '@layoutVisible')
            if (!MC.isNull(layoutVisible) && layoutVisible !== '') {
              layoutVisible = layoutVisible.split(' ')
            } else {
              layoutVisible = false
            }
            let columnVisible = MC.getFieldParamBooleanValue(fcolumn, '@visible')
            if (field.flow.modelerReact && field.flow.modelerReact.state.ghostMode) {
              columnVisible = true
            }
            if (!columnVisible || Array.isArray(layoutVisible) && !MC.isNull(this.props.resolution) && layoutVisible.indexOf(this.props.resolution) == -1) {
              continue
            }
            filter.push(<TableFilterCell key={'filter' + i} tableColumn={fcolumn} columnField={colfield} field={field}/>)
          }
        }
      }
      if (selector == 'right') {
        filter.push(<th key="select">&nbsp;</th>)
      }
      if (filter.length > 0) {
        filter = <tr className="filter-row">{filter}</tr>
      }
    }
    let draggable = MC.getFieldParamBooleanValue(field.param, '@draggable') && !MC.isModelerActive(field)
    let droppable = MC.getFieldParamBooleanValue(field.param, '@droppable') && !MC.isModelerActive(field)
    let count = MC.getRowsCount(Array.isArray(field.fields) && field.fields[0] ? field.fields[0] : field)
    if (MC.showAtLeastOneIteration(field) && count == 0) {
        count = 1
    }
    let body = []
    for (let i=0; i<count; i++) {
      let key = i
      let disabled = this.props.disabled
      let readOnly = this.props.readOnly
      let cssClass = null
      let cssRow = {}
      let subfields = Array.isArray(field.fields) && field.fields.length > 0 && field.fields[0].fields ? field.fields[0].fields : []
      let selected = false
      let textMode = this.props.textMode
      let draggableRow = draggable
      let droppableRow = droppable
      if (field.fields[0].rows) {
        let row = field.fields[0].rows[i].param
        if (false === MC.getFieldParamBooleanValue(row, '@visible')) {
          continue
        }
        if (false === MC.getFieldParamBooleanValue(row, '@enabled') || false === MC.getFieldParamBooleanValue(row, '@permitted')) {
          disabled = true
        }
        if (true === MC.getFieldParamBooleanValue(row, '@readonly')) {
          readOnly = true
        }
        if (MC.getFieldParamBooleanValue(row, '@textmode') !== null) {
          textMode = MC.getFieldParamBooleanValue(row, '@textmode')
        }
        cssClass = MC.getFieldParamValue(row, '@cssClass')
        cssRow = MC.styleObjectFromString(MC.getFieldParamValue(row, '@css'))
        subfields = field.fields[0].rows[i].fields
        selected = MC.getFieldParamBooleanValue(row, '@selected') || false
        if (MC.getFieldParamBooleanValue(row, '@draggable') !== null) {
          draggableRow = MC.getFieldParamBooleanValue(row, '@draggable')
        }
        if (MC.getFieldParamBooleanValue(row, '@droppable') !== null) {
          droppableRow = MC.getFieldParamBooleanValue(row, '@droppable')
        }
        if (MC.getFieldParamValue(row, '@id') !== null) {
          key = MC.getFieldParamValue(row, '@id')
        }
        row['@position'] = i
      }
      cssClass = MC.classes(cssClass, {'selected': selected, 'curgrab': draggableRow})
      let dragStartRow = null
      let dragOverRow = null
      let dragEnterRow = null
      let dragLeaveRow = null
      let dropRow = null
      let dradEndRow = null
      if (!MC.isModelerActive(field)) {
        if (draggableRow) {
          dragStartRow = this.dragStartRowGeneric.bind(this, i)
          dradEndRow = MC.clearDragData
        } else if (MC.getFieldParamBooleanValue(field.param, 'settings/@reorderingRows')) {
          dragStartRow = this.dragStartRow.bind(this, i)
        }
        if (droppableRow) {
          dragOverRow = this.dragOverRow
          dragEnterRow = this.dragEnterRowGeneric.bind(this, i)
          dragLeaveRow = this.dragLeaveRow.bind(this, i)
          dropRow = this.dropRowGeneric.bind(this, i)
        } else if (MC.getFieldParamBooleanValue(field.param, 'settings/@reorderingRows')) {
          dragOverRow = this.dragOverRow
          dragEnterRow = this.dragEnterRow.bind(this, i)
          dropRow = this.dropRow
        }
      }
      body.push(<tr draggable={draggableRow} onDragStart={dragStartRow} onDragEnter={dragEnterRow} onDrop={dropRow} onDragOver={dragOverRow} onDragLeave={dragLeaveRow} onDragEnd={dradEndRow} key={key} className={cssClass} style={cssRow} data-index={i}>{this.buildTableRow(field, subfields, i, disabled, readOnly, field.param.columns, selector, selected, textMode)}</tr>)
    }
    if (body.length == 0) {
      let noRowText = MC.getFieldParamValue(field.param, 'settings/@noRowText')
      if (!MC.isNull(noRowText)) {
        body.push(<tr key="noRow"><td className="no-row-column" colSpan={999}>{noRowText}</td></tr>)
      }
    }
    let fixed = MC.getFieldParamBooleanValue(field.param, 'settings/@fixed') || window.innerWidth < 768
    const tcls = MC.classes('mnc table', {'sortable': isTableSortable, 'fixed': fixed, 'collapsing': MC.getFieldParamBooleanValue(field.param, 'settings/@collapsing'), 'sticky-header': MC.getFieldParamBooleanValue(field.param, 'settings/@stickyHeader'), 'celled': resizingColumns}, MC.getFieldParamValue(field.param, '@cssClass'))
    let thead = groupsHeader || header || filter ? <thead>{groupsHeader}{header}{filter}</thead> : null
    return (
      <div className={MC.classes('tableWrapper', {'scrollable-horizontal': MC.getFieldParamBooleanValue(field.param, 'settings/@scrollableHorizontal')})} ref={this.wrapperRef}>
        <table ref={this.tableRef} className={tcls} style={MC.styleObjectFromString(MC.getFieldParamValue(field.param, '@css'))} data-widget-i-name={field.id}>
          {thead}
          <tbody>{body}</tbody>
          <tfoot><tr><td colSpan="100"></td></tr></tfoot>
        </table>
      </div>
    )
  }

}

export {Table}